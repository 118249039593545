import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BlogRedirectGuard } from "@core-guards/blog-redirect.guard";
import { LandingRedirectGuard } from "@core-guards/landing-redirect.guard";
import { CanActivateView } from "@core-guards/maintenance-page.guard";
import { AdditionalSettingResolver } from "@core-resolvers/additional-settings.resolver";
import { AboutComponent } from "./about/about.component";
import { DirectiveCommitteeComponent } from "./about/directive-committee/directive-committee.component";
import { LegalNoticeComponent } from "./about/legal-notice/legal-notice.component";
import { PaymentMethodsComponent } from "./about/payment-methods/payment-methods.component";
import { ServicesPoliciesComponent } from "./about/services-policies/services-policies.component";
import { WhoWeAreComponent } from "./about/who-we-are/who-we-are.component";
import { ContactUsComponent } from "./help/contact-us/contact-us.component";
import { FaqsComponent } from "./help/faqs/faqs.component";
import { HelpMainComponent } from "./help/help-main/help-main.component";
import { HelpTopicsComponent } from "./help/help-topics/help-topics.component";
import { HelpComponent } from "./help/help.component";
import { HomeComponent } from "./home/home.component";
import { BenefitsComponent } from "./landings/benefits/benefits.component";
import { DealsComponent } from "./landings/deals/deals.component";
import { DigitizeComponent } from "./landings/digitize/digitize.component";
import { PersonalBrandChallengeComponent } from "./landings/personal-brand/challenge/personal-brand-challenge.component";
import { PersonalBrandComponent } from "./landings/personal-brand/personal-brand.component";
import { PymesComponent } from "./landings/pymes/pymes.component";
import { MainComponent } from "./layouts/main/main.component";
import { MaintenanceComponent } from "./layouts/maintenance/maintenance.component";
import { AdditionalPurchaseSuggestionComponent } from "./purchase-suggestion/additional-purchase-suggestion.component";
import { EmailServiceComponent } from "./services/email/email-service.component";
import { HostingServiceComponent } from "./services/hosting/hosting-service.component";
import { MicrosoftServiceComponent } from "./services/microsoft/microsoft-service.component";
import { RegisterDomainsComponent } from "./services/search-domains/register-domains/register-domains.component";
import { ServicesComponent } from "./services/services.component";
import { OnlineStoreServiceComponent } from "./services/site-builder/online-store/online-store-service.component";
import { SiteBuilderComponent } from "./services/site-builder/site-builder.component";
import { WebsiteServiceComponent } from "./services/site-builder/website/website-service.component";
import { SslCertificateServiceComponent } from "./services/ssl-certificate/ssl-certificate-service.component";
import { TransferInServiceComponent } from "./services/transfer-in/transfer-in-service.component";
import { WordPressServiceComponent } from "./services/wordpress/wordpress-service.component";
import { UserEsuAccessComponent } from "./user-access/user-esu-access/user-esu-access.component";
import { DudaComponent } from "./services/duda/duda.component";


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [CanActivateView],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'esuaccess',
        component: UserEsuAccessComponent
      },
      //Help
      {
        path: 'ayuda',
        component: HelpComponent,
        children: [
          {
            path: 'contacto',
            component: ContactUsComponent
          },
          {
            path: 'ayuda',
            component: HelpMainComponent
          },
          {
            path: 'faq',
            component: FaqsComponent
          },
          {
            path: 'temas-de-ayuda',
            component: HelpTopicsComponent
          },
          {
            path: 'comunicacion/avisos',
            canActivate: [BlogRedirectGuard]
          }
        ]
      },
      {
        path: 'boletin',
        canActivate: [BlogRedirectGuard]
      },
      //About
      {
        path: 'acerca',
        component: AboutComponent,
        children: [
          {
            path: 'quienes-somos',
            component: WhoWeAreComponent
          },
          {
            path: 'nuestroequipo',
            component: DirectiveCommitteeComponent
          },
          {
            path: 'comite-directivo',
            component: DirectiveCommitteeComponent,
            canActivate: [LandingRedirectGuard]
          },
          {
            path: 'politicas-de-servicios',
            component: ServicesPoliciesComponent
          },
          {
            path: 'formas-de-pago',
            component: PaymentMethodsComponent
          },
          {
            path: 'aviso-legal',
            component: LegalNoticeComponent
          }
        ]
      },
      //Services
      {
        path: 'servicios',
        component: ServicesComponent,
        children: [
          {
            path: 'dominios',
            component: RegisterDomainsComponent
          },
          {
            path: 'transferencias',
            component: TransferInServiceComponent
          },
          {
            path: 'email',
            component: EmailServiceComponent
          },
          {
            path: 'hosting',
            component: HostingServiceComponent
          },
          {
            path: 'certificados-ssl',
            component: SslCertificateServiceComponent
          },
          {
            path: 'creador-sitios',
            component: SiteBuilderComponent,
            children:
              [
                {
                  path: 'creador-de-paginas/:type',
                  component: WebsiteServiceComponent
                },
                {
                  path: 'tienda-en-linea/:type',
                  component: OnlineStoreServiceComponent
                }
              ]
          },
          {
            path: 'wordpress/:type',
            component: WordPressServiceComponent
          },
          {
            path: 'microsoft/:type',
            component: MicrosoftServiceComponent
          },
          {
            path: 'creador-de-sitios-akky',
            component: DudaComponent
          }
        ]
      },
      {
        path: 'promociones',
        component: DealsComponent
      },
      {
        path: 'recomendaciones',
        component: AdditionalPurchaseSuggestionComponent,
        resolve: {
          data: AdditionalSettingResolver
        }
      }

    ]
  },
  {
    path: 'mimarcapersonal',
    component: PersonalBrandComponent,
    canActivate: [LandingRedirectGuard]
  },
  {
    path: 'mimarcapersonalchallenge',
    component: PersonalBrandChallengeComponent
  },
  {
    path: 'digitalizatepyme',
    component: PymesComponent
  },
  {
    path: 'emprendeconakky',
    component: PymesComponent,
    canActivate: [LandingRedirectGuard]
  },
  {
    path: 'digitalizate',
    component: DigitizeComponent
  },
  {
    path: 'beneficios-clientes',
    component: BenefitsComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    canActivate: [CanActivateView]
  },
  {
    path: "**",
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
