import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { EAdditionalService, EServiceCode } from '@core-constants/product-service.const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { ServiceHelper } from '@core-helpers/service-tools.helper';
import { IPlansDQL, IServiceBundleResponseDQL, IServiceDQL } from '@core-models/directus.model';
import { IBaseServiceBundleComponent, ILanguageComponent } from '@core-models/generic.model';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-duda',
  templateUrl: './duda.component.html',
  styleUrls: ['./duda.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DudaComponent implements OnInit, IBaseServiceBundleComponent, ILanguageComponent
{
  public _serviceID: EAdditionalService;
  public _serviceData: IServiceDQL | undefined = undefined;

  public services: IServiceBundleResponseDQL[] = undefined;
  public plans: IPlansDQL[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId,
  private router: Router,
  protected translateService: TranslateService,
  private servicesDataService: ServicesDisplayDataService,
  private ref: ChangeDetectorRef,
  private destroyRef$: DestroyRef)
  {

  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getServiceBundleInfo();
      }
    });
  }

  public getServiceBundleInfo(): void
  {
    this.services = undefined;
    const servicesNames = [EServiceCode.DudaService];

    this.servicesDataService.getBundle(servicesNames).subscribe({
      next: (response: IServiceBundleResponseDQL[]) =>
      {
        this.services = response;
        this._serviceData = this.services[0].service;
        this.plans = response[0].plans;
        this.ref.detectChanges();
        this.addViewItemListEvent(this.plans);
      }
    });
  }

  public addViewItemListEvent(items: IPlansDQL[]): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      ServiceHelper.addViewItemListEvent(items, this._serviceID);
    }
  }
}
