import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventLocationSite } from '@core-constants/gtm-const';
import { EAdditionalService, EServiceCode } from '@core-constants/product-service.const';
import { ServicesDataComparativeConst } from '@core-constants/services-data-comparative.const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { ServiceHelper } from '@core-helpers/service-tools.helper';
import { IPlansDQL, IServiceBundleResponseDQL, IServiceDQL } from '@core-models/directus.model';
import { IBaseServiceBundleComponent, ILanguageComponent } from '@core-models/generic.model';
import { IComparativeTable } from '@core-models/service-table-comparative.model';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-hosting-service',
  templateUrl: './hosting-service.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HostingServiceComponent implements OnInit, IBaseServiceBundleComponent, ILanguageComponent
{
  public _serviceID: EAdditionalService = EAdditionalService.Hosting;
  public _serviceData: IServiceDQL | undefined = undefined;

  public plans: IPlansDQL[] = [];

  public dataComparative: IComparativeTable[] = ServicesDataComparativeConst.HostingComparative;

  public locationServicePage: string = EventLocationSite.HostingPage;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private changeDetectorRef: ChangeDetectorRef,
    protected translateService: TranslateService,
    private servicesDataService: ServicesDisplayDataService,
    private destroyRef$: DestroyRef) { }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getServiceBundleInfo();
      }
    });
  }

  public getServiceBundleInfo(): void
  {
    const serviceName: string[] = [EServiceCode.Hosting];
    this.servicesDataService.getBundle(serviceName).subscribe({
      next: (response: IServiceBundleResponseDQL[]) =>
      {
        if (response)
        {
          this._serviceData = response[0].service;
          this.plans = response[0].plans;
          this.changeDetectorRef.detectChanges();
          this.addViewItemListEvent(this.plans);
        }
      }
    });
  }

  public addViewItemListEvent(items: IPlansDQL[]): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      ServiceHelper.addViewItemListEvent(items, this._serviceID);
    }
  }
}
