import { isPlatformBrowser } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";
import { EventLocationSite } from "@core-constants/gtm-const";
import { MicrosoftConst } from "@core-constants/microsoft.const";
import { NavigationConst } from "@core-constants/navigation.const";
import { EAdditionalService, EServiceCode } from "@core-constants/product-service.const";
import { ServicesDisplayDataService } from "@core-data-services/services-display.data-service";
import { ServiceHelper } from "@core-helpers/service-tools.helper";
import { ToggleHelper } from '@core-helpers/toggle.helper';
import { IPlansDQL, IServiceBundleResponseDQL, IServiceDQL } from "@core-models/directus.model";
import { IBaseServiceBundleComponent, IToggleData } from "@core-models/generic.model";
import { IColumns, IPlanTable } from "@core-models/microsoft-service-table";
import { IServiceBannerTranslation } from "@core-models/service-banner.model";
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from "@shared-utils/tools.util";
import { filter } from "rxjs";

@Component({
  selector: 'app-microsoft-service',
  templateUrl: './microsoft-service.component.html',
  styleUrls: ['./microsoft-service.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MicrosoftServiceComponent implements OnInit, IBaseServiceBundleComponent
{
  public dataToggle: IToggleData[] = NavigationConst.ServicesTogglerInfo.microsoftServices;

  public _serviceID: EAdditionalService;
  public _serviceData: IServiceDQL;

  public services: IServiceBundleResponseDQL[] = [];
  public plans: IPlansDQL[] = [];
  public tableBenefits: IPlanTable[] = MicrosoftConst.BenefitsTable;
  public selectdBenefits: IPlanTable;
  public showBenefits: boolean = false;

  public locationServicePage: string = EventLocationSite.MicrosoftPage;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private router: Router,
    protected translateService: TranslateService,
    private servicesDataService: ServicesDisplayDataService,
    private ref: ChangeDetectorRef,
    private toggleHelper: ToggleHelper,
    private destroyRef$: DestroyRef)
  {
    this.toggleHelper.changeToggle = this.dataToggle.findIndex((x: IToggleData) => this.router.url.startsWith(x.path));

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        if (this.router.url == "/servicios/microsoft")
        {
          this.router.navigate([NavigationConst.SiteRoutes.Microsoft]);
        }
      }
    });
  }

  public get selectedService(): any
  {
    const translation = this._serviceData?.traducciones;
    let serviceTranslation: IServiceBannerTranslation = undefined;

    if (translation)
    {
      serviceTranslation =
      {
        title: translation[0].titulo_seccion1,
        desciption: translation[0].titulo_seccion1,
        bannerImg: translation[0].img_banner?.id,
        bannerText: translation[0].texto_banner
      };
    }

    return serviceTranslation;
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.translateService.onTranslateChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.getServiceBundleInfo();
      }
    });

    this.toggleHelper.onToggleChange()
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe(index => this.onSelectTab(index));
  }

  public onSelectTab(index: number): void
  {
    if (!Tools.isNullOrEmpty(this.services))
    {
      const serviceCode: string = this.dataToggle[index]?.service;

      let selectedService = this.services.find((x: IServiceBundleResponseDQL) => x.service?.clave_interna === serviceCode);

      if (!selectedService)
      {
        selectedService = this.services[0];
      }

      this._serviceData = selectedService?.service;
      this.plans = this.services[0].plans.filter((x: IPlansDQL) => x.grupo == index.toString());
      this.selectdBenefits = this.tableBenefits[index];
    }

    this.toggleHelper.showFeatures = this.selectdBenefits == undefined;
    this.ref.detectChanges();
  }

  public getServiceBundleInfo(): void
  {
    this.services = [];
    const servicesNames = [EServiceCode.Microsoft];

    this.servicesDataService.getBundle(servicesNames).subscribe({
      next: (response: IServiceBundleResponseDQL[]) =>
      {
        this.services = response;
        this.onSelectTab(this.toggleHelper.changeToggle);
        this.addViewItemListEvent(this.services[0].plans);
      }
    });
  }

  public getRangeOfLongestColumnLength(columns: IColumns[]): number[]
  {
    let maxLength = 0;
    const lengths = columns.map(obj => obj.data.length);
    maxLength = lengths.reduce((max, current) => Math.max(max, current), 0);

    return [...Array(maxLength).keys()];
  }

  public addViewItemListEvent(items: IPlansDQL[]): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      ServiceHelper.addViewItemListEvent(items, this._serviceID);
    }
  }
}
