<div *ngIf="showCookiesAdvertisment" id="cookiesBg"
  class="cookies-advertisment bg-cookies-advertisment position-fixed w-100 text-end">
  <div id="cookiesAccept"
    class="cookies-advertisment text-center d-flex w-100 justify-content-center position-fixed w-100 fs-l">
    <div class="align-self-center flex-grow-1 cookies-notice">
      <span class="me-1 white align-self-center">{{'Este sitio utiliza cookies para asegurar una mejor experiencia de
        navegación.' | translate }}</span>
      <a class="white align-self-center" href="acerca/politicas-de-servicios" target="_blank">{{'Política de Privacidad'
        | translate }}.</a>
      <input type="submit" value="{{'Aceptar' | translate}}" (click)="onCookiesAdvertismentClick()"
        class="ms-2 align-self-center btn btn-secondary ">
    </div>
    <div class="m-3">
      <span class="fas i-times mr-2 d-inline-block fs-l clickable align-self-center"
        (click)="onCloseAdvertismentClick()"></span>
    </div>
  </div>
</div>

<footer>
  <!-- SERVICES -->
  <div class="container-fluid footer-color">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-3 columna columna-1">
        <span class="libre-text">
          {{'A nosotros nos encanta atenderte' | translate}}
        </span>
        <div class="position-relative">
          <img class="liebre" [src]="'/logos-akky/liebre.svg' | blobstorage"
            data-src="'/logos-akky/liebre.svg' | blobstorage" alt="Footer Akky Logo">
        </div>
      </div>
      <hr class="d-block d-md-none columna-1">
      <div class="col-12 col-md-4 col-lg-2 columna border-column services-column">
        <span class="text-uppercase fs-m">{{'ventas' | translate}}</span>
        <span class="fs-xl fw-bold"><a class="text-black" href="tel:+528188642640">+52 (81)8864-2640</a></span>
        <span class="text-uppercase fs-m">{{'asesor telefónico' | translate}}</span>
        <span class="fs-xl fw-bold"><a class="text-black" href="tel:+528188642626">+52 (81)8864-2626</a></span>
      </div>
      <hr class="oculto-tablet">
      <div class="col-12 col-md-7 col-lg-3 columna fs-m border-column">
        <div>
          <span class="fas d-inline-block i-mail icon-size"></span>
          <span class="text-uppercase">{{'envianos un correo' | translate}}</span>
        </div>
        <span>{{'Información adicional:' | translate}} <a class="text-black"
            href="mailto:ventas@akky.mx"><u>{{'ventas@akky.mx' | translate}}</u></a></span>
        <span>{{'Servicio a cliente y soporte:' | translate}} <a class="text-black"
            href="mailto:ayuda@akky.mx"><u>{{'ayuda@akky.mx' | translate}}</u></a></span>
      </div>
      <hr class="d-block d-md-none">
      <div class="col-12 col-md-5 col-lg-2 columna fs-m">
        <div class="clickable" (click)="openChat()">
          <span class="fas d-inline-block i-chat icon-size"></span><span class="text-uppercase">{{'chat en vivo' |
            translate}}</span>
        </div>
        <ng-container *ngIf="isWhatsAppIconEnabled">
          <a href="{{urlWhatsApp}}" target="_blank">
            <span class="fas d-inline-block i-whatsapp icon-size text-black"></span>
            <span class="text-uppercase text-black">{{'ventas por whatsapp' | translate}}</span>
          </a>
        </ng-container>
      </div>
    </div>
    <hr class="m-0">
    <div class="container-fluid container-lg py-4 px-2 px-sm-3 px-md-4 px-lg-5">
      <div class="row justify-content-between">
        <div class="col-12 col-md-12">
          <div class="row p-0 m-0 justify-content-between">
            <div class="col-6 col-md-2 py-2 px-1">

              <!-- ABOUT -->
              <div>
                <p class="f-title text-uppercase">{{'ACERCA' | translate}}</p>
              </div>
              <div class="mt-2 optionSpace">
                <div class="my-2" routerLink="/acerca/quienes-somos">{{'Quiénes somos' | translate}}</div>
                <div class="my-2" routerLink="/acerca/comite-directivo">{{'Nuestro equipo' | translate}}</div>
                <div class="my-2" routerLink="/acerca/politicas-de-servicios">{{'Políticas de Servicios' | translate}}
                </div>
                <div class="my-2" routerLink="/acerca/formas-de-pago">{{'Formas de Pago' | translate}}</div>
              </div>
            </div>

            <!-- SERVICE -->
            <div class="col-6 col-md-2 py-2 px-1">
              <div>
                <p class="f-title text-uppercase">{{'SERVICIOS' | translate}}</p>
              </div>
              <div class="mt-2 optionSpace">
                <div class="my-2" routerLink="/servicios/dominios">{{'Dominios' | translate}}</div>
                <div class="my-2" routerLink="/servicios/creador-sitios/creador-de-paginas/por-ti">{{'Sitios Web' |
                  translate}}</div>
                <div class="my-2" routerLink="/servicios/creador-sitios/tienda-en-linea/por-ti">{{'Tienda en Línea' |
                  translate}}</div>
                <div class="my-2" routerLink="/servicios/hosting">{{'Hosting' | translate}}</div>
                <div class="my-2" routerLink="/servicios/email">{{'Email' | translate}}</div>
                <div class="my-2" routerLink="/servicios/certificados-ssl">{{'Seguridad' | translate}}</div>
                <div class="my-2" routerLink="/servicios/microsoft/365">Microsoft</div>
                <div class="my-2" routerLink="/promociones">{{'Promociones' | translate}}</div>
              </div>
            </div>

            <!-- HELP-->
            <div class="col-6 col-md-2 py-2 px-1">
              <div>
                <p class="f-title text-uppercase">{{'AYUDA' | translate}}</p>
              </div>
              <div class="mt-2 optionSpace">
                <div class="my-2" routerLink="/ayuda/contacto">{{'Contáctanos' | translate}}</div>
                <div class="my-2" routerLink="/ayuda/faq">{{'Preguntas Frecuentes' | translate}}</div>
                <div class="my-2" routerLink="/ayuda/temas-de-ayuda">{{'Temas de ayuda' | translate}}</div>
                <div class="my-2" routerLink="/ayuda/ayuda">{{'Publicaciones' | translate}}</div>
              </div>
            </div>

            <!-- TOOLS -->
            <div class="col-6 col-md-2 py-2 px-1">
              <div>
                <p class="f-title text-uppercase">{{'HERRAMIENTAS' | translate}}</p>
              </div>
              <div class="mt-2 optionSpace">
                <div class="my-2"><span (click)="navigateToWhois()">{{'Consultar Whois' | translate}}</span></div>
                <div class="my-2"><span (click)="navigateToDnsVerify()">{{'Verificar DNS' | translate}}</span></div>
              </div>
            </div>
            <hr class="d-block d-md-none">
            <!-- Follow us-->
            <div class="col-12 col-md-4 col-lg-2 py-2 px-1">
              <div>
                <p class="f-title text-start text-md-center text-uppercase w-100">{{'sígue a la liebre' | translate}}
                </p>
              </div>
              <div class="footer-social row mt-2 justify-content-start justify-content-md-center align-items-center">
                <a class="text-decoration-none text-center col-1 col-md-2 col-lg-2 p-0 m-0"
                  href="https://www.facebook.com/AkkyMX" target="_blank" rel="noreferrer" aria-label="Facebook">
                  <span class="fas d-inline-block i-facebook size-social"></span>
                </a>
                <a class="text-decoration-none text-center col-1 col-md-2 col-lg-2 p-0 m-0"
                  href="https://www.instagram.com/akkymx/" target="_blank" rel="noreferrer" aria-label="Instagram">
                  <span class="fas d-inline-block i-instagram size-social"></span>
                </a>
                <a class="text-decoration-none text-center col-1 col-md-2 col-lg-2 p-0 m-0"
                  href="https://twitter.com/akkymx" target="_blank" rel="noreferrer" aria-label="Twitter">
                  <span class="fas d-inline-block i-x-twitter size-social"></span>
                </a>
                <a class="text-decoration-none text-center col-1 col-md-2 col-lg-2 p-0 mx-1"
                  href="https://www.youtube.com/c/Akkytienda" target="_blank" rel="noreferrer" aria-label="YouTube">
                  <span class="fas d-inline-block i-youtube size-social"></span>
                </a>
                <a class="text-decoration-none text-center col-1 col-md-2 col-lg-2 p-0 m-0"
                  href="https://mx.linkedin.com/company/akky" target="_blank" rel="noreferrer" aria-label="LinkedIn">
                  <span class="fas d-inline-block i-linkedin size-social"></span>
                </a>
              </div>
            </div>
            <hr class="d-block d-md-none">
          </div>

          <div class="row p-0 m-0 justify-content-start justify-content-lg-between">

            <!-- Payment -->
            <div class="col-12 col-md-4 mt-2 px-1">
              <div class="f-title text-uppercase my-4 my-sm-0">{{'Formas de Pago' | translate}}</div>
              <div class="d-flex mt-2 mt-md-3 pt-sm-2 justify-content-start align-items-center">
                <span class="mx-1 fas d-inline-block i-visa size-payment w-100 cardIcon"></span>
                <span class="mx-1 fas d-inline-block i-mastercard size-payment w-100 cardIcon"></span>
                <span class="mx-1 fas d-inline-block i-americanexp size-payment w-100 cardIcon"></span>
                <span class="mx-1 fas d-inline-block i-paypal size-payment w-100 cardIcon"></span>
                <span class="mx-1 fas d-inline-block i-bbva size-payment w-100 cardIcon"></span>
              </div>
            </div>

            <!-- Benefits -->
            <div class="col-12 col-md-3 col-lg-2 mt-2 px-1">
              <div class="f-title text-uppercase my-4 my-sm-0">{{'Beneficios' | translate}}</div>
              <div class="d-flex pt-sm-2 justify-content-start align-items-center">
                <img class="responsive d-inline-block mx-1 size-first-logo" loading="lazy"
                  [src]="('/images/benefits/' + warrantyImage + '.svg') | blobstorage"
                  data-src="{{('/images/benefits/' + warrantyImage + '.svg') | blobstorage}}"
                  [alt]="altForWarrantyImage">
                <img class="responsive d-inline-block mx-1 size-second-logo" loading="lazy"
                  [src]="('/images/benefits/' + supportImage + '.svg') | blobstorage"
                  data-src="{{('/images/benefits/' + supportImage + '.svg') | blobstorage}}" [alt]="altForSupportImage">
              </div>
            </div>

            <!-- Accreditations -->
            <div class="col-12 col-md-4 mt-2 px-1">
              <div class="f-title text-uppercase my-4 my-sm-0">{{'Acreditaciones' | translate}}</div>
              <div class="d-flex pt-sm-2 justify-content-start align-items-center">
                <img loading="lazy" alt="MX Logo" width="70px" class="acreditations-image d-inline-block me-1"
                  [src]="'/logos/MX.svg' | blobstorage">
                <img loading="lazy" alt="Verisign Logo" width="70px" class="acreditations-image d-inline-block mx-1"
                  [src]="'/logos/verisign.svg' | blobstorage">
                <img loading="lazy" width="70px" class="acreditations-image d-inline-block mx-1"
                  [src]="'/logos/icann.svg' | blobstorage" [alt]="altForICANNImage">
                <button class="acreditation-invisible-button" (click)="onOpenHelpPage()"
                  [attr.aria-label]="ariaLabelForICANNButton"></button>
                <img alt="iCann Logo" class="clickable trust-image ps-2" loading="lazy"
                  [src]="'/images/secure_trust.svg' | blobstorage"
                  data-src="{{ '/images/secure_trust.svg' | blobstorage }}" (click)="onOpenAcreditationPage()"
                  loading="lazy">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <hr class="footer-hr">
    <div class="row container-fluid align-items-center w-100 py-4 px-2 px-sm-3 px-md-4 px-lg-5">
      <div class="col-md-2 row justify-content-sm-start justify-content-md-end align-items-center">
        <img class="akky-logo-footer d-xl-flex justify-content-xl-start align-items-xl-start" loading="lazy" height="auto" width="180"
          alt="Akky Tu tienda de servicios digitales Logo" src="assets/logo-akky.svg" data-src="assets/logo-akky.svg">
      </div>
      <div class="col-md-10">
        <span>{{'* Las tarifas se encuentran en pesos mexicanos (MXN), e incluyen IVA.' | translate}}</span>
        <div class="row">
          <div class="d-block black fw-bold">
            Akky Online Solutions S.A de C.V. {{ currentDate | date:'yyyy' }}.
            <a class="col-md-auto underline black py-2 py-md-0" routerLink="/acerca/aviso-legal"> {{'Todos los derechos
              reservados.' | translate}}</a>
            <span>&nbsp;</span>
            <a class="col-md-auto underline black" [routerLink]="['/acerca/politicas-de-servicios']" [queryParams]="{id: 'aviso-de-privacidad-clientes'}">{{'Aviso de privacidad.'
              | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
