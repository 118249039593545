import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { EventLocationSite } from "@core-constants/gtm-const";
import { NavigationConst } from '@core-constants/navigation.const';
import { EAdditionalService, EServiceCode } from '@core-constants/product-service.const';
import { ForYouSrc, WithYourSrc } from '@core-constants/site-builder.const';
import { ServicesDisplayDataService } from '@core-data-services/services-display.data-service';
import { ServiceHelper } from '@core-helpers/service-tools.helper';
import { ToggleHelper } from '@core-helpers/toggle.helper';
import { IPlansDQL, IServiceBundleResponseDQL, IServiceDQL } from '@core-models/directus.model';
import { IBaseServiceBundleComponent, ILanguageComponent, IToggleData } from '@core-models/generic.model';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-online-store-service',
  templateUrl: './online-store-service.component.html',
  styleUrls: ['./online-store-service.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnlineStoreServiceComponent implements OnInit, IBaseServiceBundleComponent, ILanguageComponent
{
  public dataToggle: IToggleData[] = NavigationConst.ServicesTogglerInfo.onlineStoreServices;
  public _serviceID: EAdditionalService = EAdditionalService.OnlineStore;
  public _serviceData: IServiceDQL = undefined;

  public plans: IPlansDQL[] = [];
  public services: IServiceBundleResponseDQL[] = [];

  public locationServicePage: string = EventLocationSite.OnlineStorePage;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private router: Router,
    protected translateService: TranslateService,
    private servicesDataService: ServicesDisplayDataService,
    private ref: ChangeDetectorRef,
    private toggleHelper: ToggleHelper,
    private destroyRef$: DestroyRef)
  {
    this.toggleHelper.changeToggle = this.dataToggle.findIndex((x: IToggleData) => this.router.url.startsWith(x.path));

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        if (this.router.url === "/servicios/creador-sitios/tienda-en-linea")
        {
          this.router.navigate([NavigationConst.SiteRoutes.OnlineStoreForYou]);
        }
      }
    });
  }

  public get src(): SafeResourceUrl
  {
    return this._serviceData.clave_interna == EServiceCode.WebSitePT ? this.forYouSrc : this.withYourSrc;
  }

  private get forYouSrc(): string
  {
    return ForYouSrc[this.translateService.languageCode];
  }

  private get withYourSrc(): string
  {
    return WithYourSrc[this.translateService.languageCode];
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
   this.translateService.onTranslateChange()
   .pipe(takeUntilDestroyed(this.destroyRef$))
   .subscribe({
      next: () =>
      {
        this.getServiceBundleInfo();
      }
    });

   this.toggleHelper.onToggleChange()
   .pipe(takeUntilDestroyed(this.destroyRef$))
   .subscribe({
      next: (index) =>
      {
        this.onSelectTab(index);
      }
    });
  }

  public getServiceBundleInfo(): void
  {
    this.services = [];
    const servicesNames = this.dataToggle?.map(item => item.service);

    this.servicesDataService.getBundle(servicesNames).subscribe({
      next: (response : IServiceBundleResponseDQL[]) =>
      {
        if (response)
        {
          this.services = response;
          this.orderServices();
          this.onSelectTab(this.toggleHelper.changeToggle);
          this.addViewItemListEvent(response[0].plans);
        }
      }
    });
  }

  public orderServices(): void
  {
    const newArray: IServiceBundleResponseDQL[] = [];

    this.dataToggle.forEach((x: IToggleData) =>
    {
      const service = this.services.find(s => s.service.clave_interna == x.service);
      newArray.push(service);
    });

    this.services = newArray;
  }

  public onSelectTab(index: number): void
  {
    if (!Tools.isNullOrEmpty(this.services))
    {
      const serviceCode: string = this.dataToggle[index].service;

      let selectedService = this.services.find((x: IServiceBundleResponseDQL) => x.service?.clave_interna === serviceCode);

      if (!selectedService)
      {
        selectedService = this.services[0];
      }

      this._serviceData = selectedService?.service;
      this.plans = selectedService?.plans;
    }

    this.ref.detectChanges(); // forces change detection to run
  }

  public addViewItemListEvent(items: IPlansDQL[]): void
  {
    if (isPlatformBrowser(this.platformId))
    {
      ServiceHelper.addViewItemListEvent(items, this._serviceID);
    }
  }
}
