<app-base-service [serviceData]="_serviceData" [showSpecsTitle]="false">
  <ng-template #dynamicContentTemplate>
    <ng-container *ngIf="_serviceData">
      <!-- Toggler -->
      <div class="d-flex w-100 justify-content-center align-items-center">
        <app-toggle-service [data]="dataToggle"></app-toggle-service>
      </div>

      <app-service-plans [plans]="plans" [imgOnBottom]="true" [locationInit]="locationServicePage"></app-service-plans>
      <div class="row justify-content-center my-3">
        <div class="mx-auto col-10 col-md-6" [innerHTML]="_serviceData.traducciones[0]?.titulo_seccion1 | safe: 'html'"></div>
        <div class="service-under-options-img d-none d-sm-flex my-auto mx-auto col-10 col-md-6">
          <div class="embed-container mx-auto limited-size-img">
            <iframe 
              loading="lazy"
              [src]="src | safe: 'resourceUrl'" 
              frameborder="0" 
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
            </iframe>
          </div>
        </div>
      </div>
      <app-scheduler *appShellNoRender></app-scheduler>
    </ng-container>
  </ng-template>
</app-base-service>

