<app-new-base-service [serviceData]="_serviceData" [serviceComparative]="dataComparative" [serviceID]="_serviceID">
  <ng-template #dynamicContentTemplate>
    <ng-container *ngIf="_serviceData">
      <div class="row">
        <div *ngFor="let item of plans; let l = last"
             class="col-12 col-sm-6" [class.col-lg-4]="plans.length > 2" [class.col-sm-12]="l && plans.length > 2">
          <app-service-plan [plan]="item" [location]="locationServicePage"></app-service-plan>
        </div>
      </div>
    </ng-container>
  </ng-template>
</app-new-base-service>
