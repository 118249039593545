<app-base-service [serviceData]="_serviceData" [showSpecsTitle]="false">
  <ng-template #dynamicContentTemplate>
    <ng-container *ngIf="_serviceData">

      <!-- Toggler -->
      <div class="d-flex w-100 justify-content-center align-items-center">
        <app-toggle-service [data]="dataToggle"></app-toggle-service>
      </div>

      <app-service-plans [plans]="plans" [imgOnBottom]="true" [locationInit]="locationServicePage"></app-service-plans>

      <div *ngIf="selectdBenefits" class="space-blank-mcft">

      </div>

      <div *ngIf="selectdBenefits" class="container relocation px-2 px-sm-3 px-md-5">
        <button class="btn btn-whatsapp" *ngIf="!selectdBenefits.isColumnAsTable" (click)="showBenefits = !showBenefits">
          {{ 'Ver características completas' | translate }}
        </button>
        <div *ngIf="(showBenefits && selectdBenefits) || selectdBenefits.isColumnAsTable" [class.no-border]="selectdBenefits.isColumnAsTable" class="table-container">
          <table class="table-microsoft" [class.is-column-as-table]="selectdBenefits.isColumnAsTable">
            <thead>
              <tr>
                <th *ngFor="let column of selectdBenefits.columns" [ngClass]="{'d-none': !column.show}"
                  class="col-office center-title">{{column.title | translate}}</th>
              </tr>
            </thead>
            <tbody class="tb-mcft-body">
              <tr *ngFor="let row of getRangeOfLongestColumnLength(selectdBenefits.columns)">
                <td clas="center-row-app" *ngFor="let column of selectdBenefits.columns">
                  <ng-container *ngIf="column.data[row]">
                    <span class="title-apps">{{ column.data[row].title | translate }}</span>
                    <div class="row-apps" [class.is-table-content]="selectdBenefits.isColumnAsTable">
                      <div *ngFor="let app of column.data[row].apps" class="app">
                        <img [src]="app.logo | blobstorage" alt="{{app.label}} logo">
                        <span class="app-label">{{ app.label }}</span>
                      </div>
                    </div>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div class="row justify-content-center my-3" *ngIf="selectedService">
        <div class="mx-auto col-10 col-md-12" [innerHTML]="selectedService.title | safe: 'html'"></div>
      </div>

      <div class="container-fluid" *ngIf="selectedService">
        <div class="container">
          <div *ngIf="selectedService" class="row d-md-flex text-center pb-2"
            [innerHTML]="selectedService.description | safe: 'html'"></div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</app-base-service>
