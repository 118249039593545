import { HttpRequest } from "@angular/common/http";
import { StateKey } from "@angular/core";
import { EAdditionalService } from "@core-constants/product-service.const";
import { IPlansDQL } from "@core-models/directus.model";

// ********************************************************************************
// #region Classes
// ********************************************************************************

export class DropDownItem
{
  public value: number;
  public label: string;
}

export class CoverageOption
{
  public id: number;
  public value: number;
}

export class ServicePlanInfo
{
  public img: string = "";
  public title: string = "";
  public textButton: string = "";
  public textRecommended: string = "";
  public description: string = "";
  public characteristics: string = "";
  public currentPrice: string = "";
  public lastPrice: string = "";
  public cartServiceData: string = "";
  public action: string = "";
  public url: string = "";
  public gtmKey: string = "";
  public priceBackground: string = "";
  public cardBackground: string = "";
  public recommended: string = "";
  public recommendedColor: string = "";
  public textButtonCombo: string = "";
  public comboId: number = 0;
}

export class UpdateDomainServiceDto
{
  public id: number;
  public coverageId: number;
}

export class TransferStateSubroutine
{
  public apiReq: HttpRequest<any>;
  public stateKey: StateKey<string>;
}

export class StructuredDataProduct
{
  public "@type": string = "";
  public "image": string = "";
  public "description": string = "";
  public "url": string = "";
  public "name": string = "";
  public "position": string = "";
  public "offers": StructuredDataProductPrice;
}

export class StructuredDataProductPrice
{
  public "@type": string = "";
  public "priceCurrency": string = "";
  public "price": string = "";
  public "availability": string = "";
}

// #endregion Classes

// ********************************************************************************
// #region Interfaces
// ********************************************************************************

export interface IBaseServiceBundleComponent extends ILanguageComponent
{
  _serviceID: EAdditionalService;
  _serviceData: any;
  plans: any[];

  getServiceBundleInfo(): void;
  addViewItemListEvent(items: IPlansDQL[]): void;
}

export interface ILanguageComponent {
  registerEventListeners(): void;
}
export interface IIterations
{
  identify(index: any, item: any): any;
}

export interface IToggleData
{
  id: number;
  title: string;
  service: string;
  path: string;
}

// #endregion Interfaces
