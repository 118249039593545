import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageConst } from '@core-constants/language.const';
import { IServiceBundleResponseDQL } from '@core-models/directus.model';
import { IDirectusResponse } from '@core-models/graphql.models';
import { ICupon } from '@core-models/service-package.model';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ServicesDisplayDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService)
  {
    super(http, 'services');
  }

  public getGeneral(serviceName: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      service: serviceName
    };

    const subject = this.http.get(this.createUrl("general"), queryDef.getRequestConfig());

    return subject;
  }

  public getPlans(serviceId: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      id_servicio: serviceId,
      offset: Tools.getOffset()
    };

    const subject = this.http.get(this.createUrl("plans"), queryDef.getRequestConfig());

    return subject;
  }

  public getPackageById(packageId: number): Observable<ICupon>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: LanguageConst.ES,
      package_id: packageId,
      offset: Tools.getOffset()
    };

    const params = new HttpParams({ fromObject: queryDef.params });

    const options = {
      params: params,
      observe: 'body' as const
    };

    return this.http.get<IDirectusResponse<ICupon>>(this.createUrl("package"), options).pipe(
      map((response) => response.response));
  }

  public getAllPackages(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode,
      offset: Tools.getOffset()
    };

    const subject = this.http.get(this.createUrl("list/packages"), queryDef.getRequestConfig());

    return subject;
  }

  public getBundle(serviceName: string[]): Observable<IServiceBundleResponseDQL[]> 
  {
    const queryDef = new UrlQueryDef();
  
    const body = {
      language: this.translateService.languageCode,
      service: serviceName
    };
  
    const params = new HttpParams({ fromObject: queryDef.params });
  
    const options = {
      params: params,
      observe: 'body' as const
    };
  
    return this.http.post<IDirectusResponse<IServiceBundleResponseDQL[]>>(this.createUrl("servicebundle"), body, options).pipe(
      map((response) => response.response)
    );
  }
}

